import Head from 'next/head';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowDown,
    faClock,
    faCloud,
    faCode,
    faDownload,
    faPlay,
    faScroll,
    faSearch,
    faSync,
    faThumbsUp,
    faUsers,
    faUserShield,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Hidden,
    Link,
    Typography,
} from '@material-ui/core';
import { wrapper } from '../redux/store';
import { fetchFeaturedScripts } from '../redux/actions/scriptsActions';
import { RootState } from '../redux/reducers/rootReducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ScriptsState } from '../redux/reducers/scriptsReducer';
import { fetchStats } from '../redux/actions/statsActions';
import { StatsState } from '../redux/reducers/statsReducer';
import { numberWithCommas } from '../api/utils';
import { CompactScriptCard } from '../components/CompactScriptCard';
import { ClientType, SimpleUsageStatisticsResponse } from '../api/statsClient';

interface Props {
    isLoading: boolean;
    scriptsState: ScriptsState;
    statsState: StatsState;
}

function renderStats(stats: SimpleUsageStatisticsResponse) {
    return (
        <div className={styles.stats}>
            <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h2" component="p" gutterBottom>
                                {numberWithCommas(stats.usersOnline)}
                            </Typography>
                            <Typography variant="body2" component="p">
                                <b>users online right now</b>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h2" component="p" gutterBottom>
                                {numberWithCommas(stats.instancesOnline)}
                            </Typography>
                            <Typography variant="body2" component="p">
                                <b>instances of PowBot running</b>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h2" component="p" gutterBottom>
                                {numberWithCommas(stats.xpGainedToday)}
                            </Typography>
                            <Typography variant="body2" component="p">
                                <b>experience gained today</b>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3} xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h2" component="p" gutterBottom>
                                {numberWithCommas(stats.levelsGainedToday)}
                            </Typography>
                            <Typography variant="body2" component="p">
                                <b>levels gained today</b>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
function Home({ isLoading, scriptsState, statsState }: Props) {
    return (
        <div className={styles.container}>
            <Head>
                <script
                    type="text/javascript"
                    src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                    async
                ></script>

                <link rel="canonical" href="https://powbot.org/" />

                <link
                    rel="preload"
                    href="/fonts/bebas/Bebas-Regular.ttf"
                    as="font"
                    crossOrigin=""
                />
                <title>
                    #1 Mobile Bot Client for Old School RuneScape© | PowBot
                </title>
                <link rel="icon" href="/favicon.ico" />
                <meta
                    name="description"
                    content="Formerly known as Powerbot / RSBot. The #1 scriptable mobile bot for OSRS, with a focus on undetectability. High quality bots for Woodcutting, Mining, Slayer and more."
                />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://powbot.org/" />
                <meta
                    property="og:title"
                    content="#1 Mobile Bot Client for Old School RuneScape© | PowBot"
                />
                <meta
                    property="og:description"
                    content="Formerly known as Powerbot / RSBot. The #1 scriptable bot for OSRS, with a focus on undetectability. High quality bots for Woodcutting, Mining, Slayer and more."
                />
                <meta
                    name="og:image"
                    content="https://powbot.org/socials.png"
                />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@powbot_" />
                <meta name="twitter:creator" content="@powbot_" />
                <meta
                    name="twitter:title"
                    content="#1 Mobile Bot Client for Old School RuneScape© | PowBot"
                />
                <meta
                    name="twitter:description"
                    content="Formerly known as Powerbot / RSBot. The #1 scriptable bot for OSRS Mobile, with a focus on undetectability. High quality bots for Woodcutting, Mining, Slayer and more."
                />
                <meta
                    name="twitter:image"
                    content="https://powbot.org/socials.png"
                />

                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: `{
  "@context": "http://schema.org",
  "@type": "WebSite",
  "url": "https://powbot.org",
  "keywords": "mobile, bot, osrs mobile bot, osrs bot,runescape bot,oldschool runescape bot,osrs scripts,bot scripts,woodcutting bot,mining bot,zulrah bot,slayer bot",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://powbot.org/scripts?&text={query}",
    "query": "required",
    "query-input": "required maxlength=100 name=query"
  }
}`.trimLeft(),
                    }}
                />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: `{
  "@context": "http://schema.org",
  "@type": "Organization",
  "name": "PowBot",
  "email": "info@powbot.org",
  "url": "https://powbot.org/",
  "logo": "https://powbot.org/logo.png",
  "sameAs": [
    "https://www.facebook.com/powbot.org",
    "https://www.twitter.com/powbot_",
    "https://www.youtube.com/channel/UCCjN5msoo4-x7Uzgczt12uQ"
  ]
}`.trimLeft(),
                    }}
                />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: `{
  "@context": "http://schema.org",
  "@type": "SoftwareApplication",
  "name": "PowBot",
  "offers": {
    "@type": "Offer",
    "price": "0",
    "priceCurrency": "USD"
  },
  "applicationCategory": "GameApplication",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "0",
    "worstRating": "0",
    "bestRating": "5",
    "ratingCount": "1"
  },
  "downloadUrl": "https://powbot.org/game/PowBotLoader.jar",
  "installUrl": "https://powbot.org/",
  "operatingSystem": "Windows,Linux,OS X"
}`.trimLeft(),
                    }}
                />
            </Head>

            <main className={styles.main}>
                <div className={styles.mainHeader}>
                    <Container>
                        <Typography variant="h1" component="h1" gutterBottom>
                            PowBot - The Next Generation Of RSBot
                        </Typography>

                        <h2>Mobile Client</h2>
                        {renderStats(statsState.stats[ClientType.MOBILE])}

                        <Typography
                            variant="h3"
                            component="p"
                            className={styles.subtitle}
                        >
                            PowBot is a scriptable bot client for OldSchool
                            RuneScape Mobile, originating from the RSBot
                            community - the longest lasting botting community
                            around.
                        </Typography>

                        <Typography
                            variant="h3"
                            component="p"
                            className={styles.subtitle}
                        >
                            Note: A VIP subscription is required to use the
                            client, click{' '}
                            {
                                <Link
                                    className={styles.upgradeLink}
                                    href="/community/index.php?/subscriptions"
                                >
                                    here
                                </Link>
                            }{' '}
                            to see the available options.
                        </Typography>

                        <div className={styles.mainActions}>
                            <Link href="/download">
                                <Button className={styles.downloadButton}>
                                    <FontAwesomeIcon icon={faDownload} />
                                    Download PowBot
                                </Button>
                            </Link>
                            <Link href="/scripts">
                                <Button className={styles.searchButton}>
                                    <FontAwesomeIcon icon={faSearch} />
                                    Browse scripts
                                </Button>
                            </Link>
                        </div>
                    </Container>
                </div>

                <div className={styles.about}>
                    <Container>
                        <div className={styles.whatIsPowBot}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <div className={styles.sectionBody}>
                                        <Typography
                                            variant="h2"
                                            component="h2"
                                            gutterBottom
                                        >
                                            What is PowBot?
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            PowBot is an application for your
                                            computer that allows you to automate
                                            a wide range of tasks in the game
                                            OldSchool RuneScape. These types of
                                            applications are widely known as
                                            bots or macros. Bots can be used for
                                            levelling up combat skills,
                                            moneymaking, and even skills such as
                                            Woodcutting, Mining and Slayer.
                                        </Typography>
                                    </div>
                                </Grid>
                                <Hidden smDown>
                                    <Grid item xs={12} md={6}>
                                        <div className={styles.sectionPicture}>
                                            <img
                                                src="/graphics/chonky_robot.png"
                                                width="100%"
                                            />
                                        </div>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </div>

                        <div className={styles.howDoesPowBotWork}>
                            <Grid container spacing={3}>
                                <Hidden smDown>
                                    <Grid item md={6}>
                                        <div className={styles.sectionPicture}>
                                            <img
                                                src="/graphics/lanky_robot.png"
                                                width="100%"
                                            />
                                        </div>
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12} md={6}>
                                    <div className={styles.sectionBody}>
                                        <Typography
                                            variant="h2"
                                            component="h2"
                                            gutterBottom
                                        >
                                            How does PowBot work?
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            PowBot operates using scripts. You
                                            can imagine scripts as being a list
                                            of lots of instructions, telling the
                                            bot client what to click, when to
                                            click it, and so on. You can either
                                            make these scripts yourself, or
                                            acquire them from the script store
                                            where there are a range of free and
                                            premium scripts available.
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <div className={styles.tonsOfScripts}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <div className={styles.sectionBody}>
                                        <Typography
                                            variant="h2"
                                            component="h2"
                                            gutterBottom
                                        >
                                            Tons of Scripts
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            PowBot has some of the best free and
                                            premium scripts available on the
                                            market, helping you automate a wide
                                            range of in-game tasks. Whether
                                            you're interested in training skills
                                            like Woodcutting and Mining, killing
                                            boss monsters like Zulrah or
                                            automating your Slayer tasks, our
                                            script store has something for
                                            everyone!
                                        </Typography>
                                    </div>

                                    <div className={styles.arrow}>
                                        <FontAwesomeIcon
                                            icon={faArrowDown}
                                            size="3x"
                                        />
                                    </div>

                                    <div className={styles.scripts}>
                                        <Grid container spacing={1}>
                                            {(
                                                scriptsState.featuredScripts ||
                                                []
                                            ).map((script) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    key={script.id}
                                                >
                                                    <CompactScriptCard
                                                        script={script}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>

                                    <div className={styles.callToAction}>
                                        <Link href={'/scripts'}>
                                            <Button
                                                className={styles.viewButton}
                                            >
                                                View more scripts
                                            </Button>
                                        </Link>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className={styles.sectionPicture}>
                                        <img
                                            src="/graphics/scripts.png"
                                            width={'100%'}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>

                <div className={styles.features}>
                    <div className={styles.sectionHeader}>
                        <Typography variant="h2" component="h2" gutterBottom>
                            Plenty of Features
                        </Typography>
                        <Typography
                            variant="h3"
                            component="p"
                            className={styles.subtitle}
                        >
                            PowBot is one of the most feature-rich bots on the
                            market, see for yourself!
                        </Typography>
                    </div>

                    <Container>
                        <Grid
                            container
                            spacing={2}
                            className={styles.featureList}
                        >
                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={styles.feature}>
                                    <CardContent>
                                        <div className={styles.featureIcon}>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                size="3x"
                                            />
                                        </div>

                                        <Typography
                                            variant="h5"
                                            component="h3"
                                            gutterBottom
                                        >
                                            Easy to Use
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Our client works on Mac, Windows and
                                            Linux. Simply download it above,
                                            sign in with your account, select
                                            your scripts and you're ready to go.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={styles.feature}>
                                    <CardContent>
                                        <div className={styles.featureIcon}>
                                            <FontAwesomeIcon
                                                icon={faScroll}
                                                size="3x"
                                            />
                                        </div>

                                        <Typography
                                            variant="h5"
                                            component="h3"
                                            gutterBottom
                                        >
                                            Lots of Scripts
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            With plenty of high quality scripts
                                            available for you to choose from,
                                            the bot can complete virtually any
                                            task within the game. We offer both
                                            premium and free scripts, catering
                                            for any budget.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={styles.feature}>
                                    <CardContent>
                                        <div className={styles.featureIcon}>
                                            <FontAwesomeIcon
                                                icon={faUserShield}
                                                size="3x"
                                            />
                                        </div>

                                        <Typography
                                            variant="h5"
                                            component="h3"
                                            gutterBottom
                                        >
                                            Built By Veterans
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            PowBot is developed and maintained
                                            by experts, with bot development
                                            experience going back as far as
                                            2006. Our team originates from the
                                            well-known RSBot community.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={styles.feature}>
                                    <CardContent>
                                        <div className={styles.featureIcon}>
                                            <FontAwesomeIcon
                                                icon={faClock}
                                                size="3x"
                                            />
                                        </div>

                                        <Typography
                                            variant="h5"
                                            component="h3"
                                            gutterBottom
                                        >
                                            Customisable Breaks
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Our fully customisable break system
                                            enables human-like behaviour, by
                                            allowing you to configure breaks
                                            based on parameters such as time
                                            played and XP/levels gained. This
                                            helps make the bot harder to detect.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={styles.feature}>
                                    <CardContent>
                                        <div className={styles.featureIcon}>
                                            <FontAwesomeIcon
                                                icon={faCloud}
                                                size="3x"
                                            />
                                        </div>

                                        <Typography
                                            variant="h5"
                                            component="h3"
                                            gutterBottom
                                        >
                                            Advanced Remote Control
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Set up and monitor your bots from
                                            wherever you are with the PowBot
                                            control center. Select scripts,
                                            configure breaks, start and stop
                                            your bots at will, all without
                                            having to sit in front of your
                                            computer.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={styles.feature}>
                                    <CardContent>
                                        <div className={styles.featureIcon}>
                                            <FontAwesomeIcon
                                                icon={faUsers}
                                                size="3x"
                                            />
                                        </div>

                                        <Typography
                                            variant="h5"
                                            component="h3"
                                            gutterBottom
                                        >
                                            Thriving Community
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            PowBot is backed by a strong
                                            community of passionate RuneScape
                                            players, script writers and client
                                            developers. Join the discussions on
                                            our forum and official Discord
                                            server.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={styles.feature}>
                                    <CardContent>
                                        <div className={styles.featureIcon}>
                                            <FontAwesomeIcon
                                                icon={faCode}
                                                size="3x"
                                            />
                                        </div>

                                        <Typography
                                            variant="h5"
                                            component="h3"
                                            gutterBottom
                                        >
                                            Make Your Own
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Want to automate something not
                                            available in our extensive
                                            repository? Our open-source
                                            scripting API and detailed guides
                                            make it easy to get started with
                                            writing your own scripts using Java
                                            or Kotlin.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={styles.feature}>
                                    <CardContent>
                                        <div className={styles.featureIcon}>
                                            <FontAwesomeIcon
                                                icon={faSync}
                                                size="3x"
                                            />
                                        </div>

                                        <Typography
                                            variant="h5"
                                            component="h3"
                                            gutterBottom
                                        >
                                            Always Improving
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            We are constantly working on new
                                            updates to improve the experience of
                                            our users. Our community is heavily
                                            involved in deciding our development
                                            roadmap, so feel free to join and
                                            get involved!
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Card className={styles.feature}>
                                    <CardContent>
                                        <div className={styles.featureIcon}>
                                            <FontAwesomeIcon
                                                icon={faThumbsUp}
                                                size="3x"
                                            />
                                        </div>

                                        <Typography
                                            variant="h5"
                                            component="h3"
                                            gutterBottom
                                        >
                                            RSBOT / Powerbot Compatible
                                        </Typography>

                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Are you a former user of RSBot /
                                            Powerbot? PowBot originates from the
                                            same roots as Powerbot and is fully
                                            backwards compatible, meaning almost
                                            all of the Powerbot scripts you know
                                            and love are available here!
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </main>
        </div>
    );
}

export const getServerSideProps = wrapper.getServerSideProps(
    async (context) => {
        await context.store.dispatch(fetchStats(context, ClientType.MOBILE));
        await context.store.dispatch(fetchFeaturedScripts(context));
    },
);

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.shared.isLoading,
        scriptsState: state.scripts,
        statsState: state.stats,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFeaturedScripts: bindActionCreators(
            fetchFeaturedScripts,
            dispatch,
        ),
        fetchStats: bindActionCreators(fetchStats, dispatch),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
