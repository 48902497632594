import React from 'react';
import { Card, CardContent, Link, Typography } from '@material-ui/core';
import {
    ProductSubscriptionType,
    SimpleProductResponse,
} from '../api/productsClient';
import styles from './CompactScriptCard.module.scss';
import { formatProductPrice } from '../api/utils';

interface Props {
    script: SimpleProductResponse;
}

function isScriptFree(script: SimpleProductResponse): boolean {
    return (
        script.purchaseOptions.find(
            (p) => p.subscriptionType == ProductSubscriptionType.Free,
        ) != null
    );
}

function getScriptType(script: SimpleProductResponse): string {
    switch (script.purchaseOptions[0].subscriptionType) {
        case ProductSubscriptionType.Free:
            return 'Free';
        case ProductSubscriptionType.PaidLifetime:
            return 'Premium (once off)';
        case ProductSubscriptionType.PaidMonthly:
            return 'Premium (monthly)';
        case ProductSubscriptionType.PremiumPass:
            return 'Premium Pass (VIP users)';
        default:
            return 'Unknown';
    }
}

function formatSubscribers(subscribers: number) {
    if (subscribers > 1000) {
        return `${subscribers / 100}+`;
    }

    return subscribers;
}

export function CompactScriptCard({ script }: Props) {
    return (
        <Link href={'/scripts/' + script.slug} className={styles.scriptLink}>
            <Card
                key={script.id}
                className={styles.scriptCard}
                elevation={12}
                itemScope
                itemType="https://schema.org/Product"
            >
                <meta itemProp="name" content={script.name} />
                <meta itemProp="description" content={script.description} />
                <meta
                    itemProp="category"
                    content={
                        script.category.charAt(0).toLowerCase() +
                        script.category.substr(1)
                    }
                />

                <span
                    itemProp="offers"
                    itemScope
                    itemType="https://schema.org/Offer"
                >
                    <meta itemProp="priceCurrency" content="USD" />
                    <meta
                        itemProp="price"
                        content={'' + script.purchaseOptions[0].price}
                    />
                    <meta itemProp="seller" content={script.author} />
                </span>

                <CardContent className={styles.scriptContent}>
                    <div className={styles.top}>
                        <div
                            className={
                                script.purchaseOptions[0].price == 0
                                    ? styles.freeHeader
                                    : styles.premiumHeader
                            }
                        >
                            {formatProductPrice(script)}
                        </div>
                        <div className={styles.body}>
                            <div className={styles.headerContainer}>
                                <Typography variant="h5" component="h2">
                                    {script.name}
                                </Typography>
                            </div>
                            <Typography
                                variant="body2"
                                className={styles.author}
                                component="h6"
                            >
                                By {script.author}
                            </Typography>
                            <Typography
                                variant="body2"
                                component="p"
                                className={styles.description}
                            >
                                {script.description}
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Link>
    );
}
